<template>
  <div>
    <div class="headbox">
      <img class="logo" src="../assets/index/logo.png" alt="logo">
      <div class="navbox">
        <div :class="nav == -1 ? 'on navitem': 'navitem'" @click="tohome()">
          {{Language.head.home}}
        </div>
        <div :class="nav == 1 ? 'on navitem': 'navitem'" @click="toourServices()" @mouseover="changeshownavlist2true"
             @mouseleave="changeshownavlist2false">
          <div>{{Language.head.our_services}}<i class="iconfont icon-xiangxia"></i></div>
          <div class="navlist" v-show="shownavlist2">
            <div :class="nav3 == 1 ? 'on':''" @click.stop="tosearveview(1)">{{Language.head.certification}}</div>
            <div :class="nav3 == 2 ? 'on':''" @click.stop="tosearveview(2)">{{Language.head.test_service}}</div>
            <div :class="nav3 == 3 ? 'on':''" @click.stop="tosearveview(3)">{{Language.head.quality_control}}</div>
            <div :class="nav3 == 4 ? 'on':''" @click.stop="tosearveview(4)">{{Language.head.technical_advice}}</div>
            <div :class="nav3 == 5 ? 'on':''" @click.stop="tosearveview(5)">{{Language.head.E_commerce_services}}</div>
          </div>
        </div>
        <div :class="nav == 2 ? 'on navitem': 'navitem'" style="flex-shrink: 0" @click="toserviceProducts()" @mouseover="changeshownavlist3true"
             @mouseleave="changeshownavlist3false">
          <div>{{Language.head.products}}<i class="iconfont icon-xiangxia"></i></div>
          <div class="navlist" v-show="shownavlist3">
            <div :class="nav4 == 1 ? 'on':''" @click.stop="tochildProduct(1)">{{Language.head.Christmas_lighting_and_gifts}}</div>
            <div :class="nav4 == 2 ? 'on':''" @click.stop="tochildProduct(2)">{{Language.head.Infant_and_child_products_and_toys}}</div>
            <div :class="nav4 == 3 ? 'on':''" @click.stop="tochildProduct(3)">{{Language.head.textile}}</div>
            <div :class="nav4 == 4 ? 'on':''" @click.stop="tochildProduct(4)">{{Language.head.Outdoor_products}}</div>
            <div :class="nav4 == 5 ? 'on':''" @click.stop="tochildProduct(5)">{{Language.head.Furniture_teaching_aids}}</div>
            <div :class="nav4 == 6 ? 'on':''" @click.stop="tochildProduct(6)">{{Language.head.electrical_and_electronic_product}}</div>
            <div :class="nav4 == 7 ? 'on':''" @click.stop="tochildProduct(7)">{{Language.head.Food_contact_materials}}</div>
          </div>
        </div>
        <div :class="nav == 3 ? 'on navitem': 'navitem'"  @click="tomanagementSystem()">
          {{Language.head.quality_control_management}}
        </div>
        <div :class="nav == 5 ? 'on navitem': 'navitem'" @click="toreportQuery()">
          {{Language.head.report_view}}
        </div>
        <div :class="nav == 6 ? 'on navitem': 'navitem'" @click="toaboutUs()">
          {{Language.head.about_us}}
        </div>
        <div :class="nav == 7 ? 'on navitem': 'navitem'" @click="tocontactUs()">
          {{Language.head.contact}}
        </div>
        <div :class="nav == 4 ? 'on navitem': 'navitem'" @mouseover="changeshownavlisttrue"
             @mouseleave="changeshownavlistfalse">
          {{Language.head.resources}}<i class="iconfont icon-xiangxia"></i>
          <div class="navlist" v-show="shownavlist">
            <div :class="nav2 == 1 ? 'on':''" @click.stop="toknowledgeList()">{{Language.head.Inspection_and_testing}}</div>
            <div :class="nav2 == 2 ? 'on':''" @click.stop="tonews()">{{Language.head.Company_News}}</div>
            <div :class="nav2 == 3 ? 'on':''" @click.stop="todataDownload()">{{Language.head.Data_download}}</div>
          </div>
        </div>
      </div>

      <div class="phonebox">
        <!--        <div class="line1">-->
        <!--          <div><i class="iconfont icon-dianhua"></i> +86-591-8756 2601</div>-->
        <!--        </div>-->
        <div class="line2">
          <div style="margin-right: 10px;cursor: pointer;width: 50px">
            <div @click="showLanguage = !showLanguage">
              <span v-if="Language_type == 1">中文</span><span v-if="Language_type == 2">EN</span> <i class="iconfont icon-xiangxia"></i>
            </div>
            <div class="Language" v-show="showLanguage">
              <div @click="changeLanguage(1)">中文</div>
              <div @click="changeLanguage(2)">English</div>
            </div>
          </div>
          <div class="login" @click="tologin" rel=nofollow>{{Language.head.Login}}</div>
          <div class="register" @click="tologin2" rel=nofollow>{{Language.head.register}}</div>
        </div>
      </div>
    </div>


    <div class="headbox2">
      <div class="mobilenav">
        <div class="logoflex">
          <img class="logo" src="../assets/dsf.png" alt="logo"/>
          <img class="cpname" src="../assets/companyname.png" alt="logo"/>
        </div>
        <div class="rightbtn">
          <img @click="tohome()" class="button" src="../assets/botton1.png">
          <img @click="tologin" class="button" src="../assets/botton2.png">
          <img @click="showmobilenavbox = true" class="button" src="../assets/botton3.png">
        </div>
        <div class="mobilenavbox" v-if="showmobilenavbox">
          <div class="close" @click="showmobilenavbox = false">×</div>
          <div class="bigitem">
            <div class="flexbox" @click="toourServices()">
              <div class="title">{{Language.head.our_services}}</div>
              <div class="more">
                <i v-if="!mobileshow1" @click.stop="mobileshow1 = true" class="iconfont icon-xiangxia" style="font-size: 20px"></i>
                <i v-if="mobileshow1" @click.stop="mobileshow1 = false" class="iconfont icon-xiangshang" style="font-size: 20px"></i>
              </div>
            </div>
            <div v-if="mobileshow1">
              <div class="item" @click="tosearveview(1)">
                <div>{{Language.head.certification}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tosearveview(2)">
                <div>{{Language.head.test_service}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tosearveview(3)">
                <div>{{Language.head.quality_control}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tosearveview(4)">
                <div>{{Language.head.technical_advice}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tosearveview(5)">
                <div>{{Language.head.E_commerce_services}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
            </div>
          </div>
          <div class="bigitem">
            <div class="flexbox" @click="toserviceProducts">
              <div class="title">{{Language.head.products}}</div>
              <div class="more">
                <i v-if="!mobileshow2" @click.stop="mobileshow2 = true" class="iconfont icon-xiangxia" style="font-size: 20px"></i>
                <i v-if="mobileshow2" @click.stop="mobileshow2 = false" class="iconfont icon-xiangshang" style="font-size: 20px"></i>
              </div>
            </div>
            <div v-if="mobileshow2">
              <div class="item" @click="tochildProduct(1)">
                <div>{{Language.head.Christmas_lighting_and_gifts}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tochildProduct(2)">
                <div>{{Language.head.Infant_and_child_products_and_toys}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tochildProduct(3)">
                <div>{{Language.head.textile}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tochildProduct(4)">
                <div>{{Language.head.Outdoor_products}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tochildProduct(5)">
                <div>{{Language.head.Furniture_teaching_aids}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tochildProduct(6)">
                <div>{{Language.head.electrical_and_electronic_product}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tochildProduct(7)">
                <div>{{Language.head.Food_contact_materials}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
            </div>
          </div>
          <div class="bigitem">
            <div class="flexbox" @click="tomanagementSystem()">
              <div class="title">{{Language.head.quality_control_management}}</div>
              <div class="more">
                <i class="iconfont icon-xiangyou"></i>
              </div>
            </div>
          </div>

          <div class="bigitem">
            <div class="flexbox" @click="toreportQuery()">
              <div class="title">{{Language.head.report_view}}</div>
              <div class="more">
                <i class="iconfont icon-xiangyou"></i>
              </div>
            </div>
          </div>

          <div class="bigitem">
            <div class="flexbox" @click="toaboutUs()">
              <div class="title">{{Language.head.about_us}}</div>
              <div class="more">
                <i class="iconfont icon-xiangyou"></i>
              </div>
            </div>
          </div>

          <div class="bigitem">
            <div class="flexbox" @click="tocontactUs()">
              <div class="title">{{Language.head.contact}}</div>
              <div class="more">
                <i class="iconfont icon-xiangyou"></i>
              </div>
            </div>
          </div>

          <div class="bigitem">
            <div class="flexbox">
              <div class="title">{{Language.head.resources}}</div>
              <div class="more">
                <i v-if="!mobileshow3" @click="mobileshow3 = true" class="iconfont icon-xiangxia" style="font-size: 20px"></i>
                <i v-if="mobileshow3" @click="mobileshow3 = false" class="iconfont icon-xiangshang" style="font-size: 20px"></i>
              </div>
            </div>
            <div v-if="mobileshow3">
              <div class="item" @click="toknowledgeList()">
                <div>{{Language.head.Inspection_and_testing}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="tonews()">
                <div>{{Language.head.Company_News}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
              <div class="item" @click="todataDownload()">
                <div>{{Language.head.Data_download}}</div>
                <div><i class="iconfont icon-xiangyou"></i></div>
              </div>
            </div>
          </div>

          <div class="languagebtns">
            <div @click="changeLanguage(1)" :class="Language_type == 1 ? 'on': ''">中文</div>
            <div @click="changeLanguage(2)" :class="Language_type != 1 ? 'on': ''">EN</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myhead",
  props: ["nav", "nav2", "nav3", "nav4"],
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  data() {
    return {
      Language_type:"",
      showLanguage:false,
      shownavlist: false,
      shownavlist2: false,
      shownavlist3: false,
      navurl: [
        {
          title: '认证服务',
          url: '/certification'
        },
        {
          title: '测试服务',
          url: '/products'
        },
        {
          title: '质量管理',
          url: '/our-services/quality-control-management'
        },
        {
          title: '技术咨询',
          url: '/our-services/technical-consulting'
        },
        {
          title: '电商服务',
          url: '/our-services/e-commerce'
        }
      ],
      nav2url: [
        {
          title: '节日礼品',
          url:'/products/christmas-lights-and-gifts'
        },
        {
          title: '婴童用品及玩具',
          url:'/products/baby-products-and-toys'
        },
        {
          title: '纺织品',
          url:'/products/apparel-and-textiles'
        },
        {
          title: '户外用品',
          url:'/products/outdoor-goods'
        },
        {
          title: '家具教具',
          url:'/products/furniture'
        },
        {
          title: '电子电气产品',
          url:'/products/electronics'
        },
        {
          title: '食品接触材料',
          url:'/products/food-contact-material'
        }
      ],
      mobileshow1:false,
      mobileshow2:false,
      mobileshow3:false,
      showmobilenavbox:false
    }
  },
  mounted() {
    this.Language_type = localStorage.getItem("Language_type")
  },
  methods: {
    changeLanguage(e){
      localStorage.setItem("Language_type",e)
      this.Language_type = e
      this.showLanguage = false
      this.reload()
    },
    tohome() {
      this.$router.push("/")
    },
    toourServices() {
      this.$router.push("/our-services")
    },
    toserviceProducts() {
      this.$router.push("/products")
    },
    tomanagementSystem() {
      this.$router.push("/our-services/quality-control-management")
    },
    todataDownload() {
      this.$router.push("/resources/download")
    },
    toreportQuery() {
      this.$router.push("/report-view")
    },
    toaboutUs() {
      this.$router.push("/about-us")
    },
    tocontactUs() {
      this.$router.push("/contact")
    },
    changeshownavlisttrue() {
      this.shownavlist = true
    },
    changeshownavlistfalse() {
      this.shownavlist = false
    },
    changeshownavlist2true() {
      this.shownavlist2 = true
    },
    changeshownavlist2false() {
      this.shownavlist2 = false
    },
    changeshownavlist3true() {
      this.shownavlist3 = true
    },
    changeshownavlist3false() {
      this.shownavlist3 = false
    },
    tosearveview(type) {
      this.$router.push(this.navurl[type - 1].url)
    },
    tochildProduct(type) {
      this.$router.push(this.nav2url[type - 1].url)
      this.reload()
    },
    toknowledgeList() {
      this.$router.push("/resources/blog")
    },
    tologin() {
      window.location.href = 'https://qc-plus.cn/admin/user/login?language=' + localStorage.getItem("Language_type")
    },
    tologin2() {
      window.location.href = 'https://qc-plus.cn/admin/user/register?language=' + localStorage.getItem("Language_type")
    },
    tonews(){
      this.$router.push("/resources/news")
    },
  }
}
</script>

<style lang="scss" scoped>
.Language{
  line-height: 25px;
  margin-top: 5px;
}

.headbox2{
  display: none !important;
}

.headbox {
  height: 70.5px;
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: auto;
  //background-color: #1989fa;
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  font-family: 微软雅黑;
  font-size: 14px;

  .logo {
    width: 60px;
    height: 60px;
    margin-top: 6px;
  }


  .navbox {
    width: calc(100% - 60px - 244px);
    display: flex;
    justify-content: space-around;
    //align-items: center;
    margin-top: 20.5px;

    .navitem {
      cursor: pointer;
      position: relative;
      padding: 0px 20px;
      .navlist {
        background-color: rgba(0,0,0,0.5);
        width: 120px;
        position: absolute;
        color: #FFFFFF;
        //line-height: 30px;
        margin-top: 5px;
        div{
          padding: 5px 10px;
        }
      }
    }

    .on {
      color: #00AD61;
    }
  }


  .phonebox {
    width: 234px;
    height: 56px;
    margin-top: 14.5px;

    .line1 {
      display: flex;
      justify-content: space-between;
    }

    .line2 {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;

      .login {
        cursor: pointer;
        width: 72px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #27BA69;
        border-radius: 4px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        margin-right: 8px
      }

      .register {
        cursor: pointer;
        width: 72px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
      }
    }
  }
}

.mobilenav{
  height: 45px;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  .logoflex{
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .logo{
    height: 45px;
    padding: 5px;
  }
  .cpname{
    height: 15px;
  }
  .rightbtn{
    display: flex;
    align-items: center;
    margin-right: 10px;
    .button{
      width: 40px;
      padding: 5px;
      cursor: pointer;
    }
  }

  .mobilenavbox{
    position: absolute;
    right: 0px;
    background-color: #fff;
    top: 46px;
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid #333333;
    .close{
      text-align: right;
      padding: 5px 15px 0px;
      font-size: 25px;
      cursor: pointer;
    }
    .bigitem{
      margin: 0px 20px;
      .flexbox{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #a7a7a7;
        padding: 10px 0px;
        .title{
          font-size: 20px;
          color: #333333;
          cursor: pointer;
        }
        .more{
          font-weight: bold;
          cursor: pointer;
        }
      }

      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        border-bottom: 1px solid #eceef2;
        font-size: 14px;
      }
    }

    .languagebtns{
      display: flex;
      margin: 20px;
      border: 1px solid #333333;
      border-radius: 5px;
      line-height: 35px;
      div{
        width: 50%;
        text-align: center;
      }
      .on{
        background-color: #333333;
        color: #fff;
      }
    }
  }
}

@media (min-width: 800px) and (max-width: 1200px){
  .headbox{
    width: calc(100% - 20px);
    .navbox{
      .navitem{
        padding: 0 5px;
      }
    }
  }
}
@media (min-width: 500px) and (max-width: 800px){
  .headbox{
    display: none !important;;
  }
  .headbox2{
    display: block !important;
  }
}
@media (min-width: 0px) and (max-width: 500px) {
  .headbox {
    display: none !important;;
  }
  .headbox2{
    display: block !important;
  }
}
</style>